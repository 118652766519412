.main_submit_btn {
    position:fixed;
    top:20px;
    right:100px;
    z-index:1030;
}

.panel .panel-heading {
    padding: 0 10px;
}

.list-unstyled.breadcrumb {
    margin:0;
}

.delete_image_btn {
    position:absolute;
    right:0;
    bottom:0;
}

.dataTables_length,
.dataTables_filter,
.dt-buttons {
    margin-bottom: 0.333em;
    margin-top: .2rem;
}

.dataTables_filter {
    margin-right: .2rem;
}

.table.dataTable {
    box-sizing: border-box;
    border-collapse: collapse;
}

table.dataTable thead th {
    border-bottom: 2px solid #c8ced3;
}

.dataTables_wrapper {
    .dt-buttons {
        display:flex;
        .btn {
            margin:1px;
        }
    }
    .dataTables_length, .dataTables_filter {
        @media(max-width:575px) {
            text-align: left !important;
        }
    }
}

.dataTables_wrapper.no-footer .dataTables_scrollBody {
    border-bottom: 1px solid #c8ced3;
}
.dataTable {
    width: 100% !important;
    font-size:12px;
}

.dataTable tbody tr.selected {
    background-color: #4f5d73 !important;
    color:white !important;
}

li.addnew {
    a {
        padding:0;
    }
    position: absolute !important;
    top:15px;
    right:15px;
}

.dropzone .dz-preview .dz-image {
    border-radius: 0;
    img {
        width: 100%;
        height:100%;
        object-fit: cover;
    }
}

.select2-container {
    z-index:1;
}
.sort_handle {
    cursor:ns-resize;
}
