.image-loader-modal {
    position:fixed;
    z-index:9999;
    padding-top:100px;
    left:0;
    top:0;
    width:100%;
    height:100%;
    overflow:auto;
    background-color:rgb(0,0,0);
    background-color:rgba(0,0,0,0.5);
    .modal-body {
        position:relative;
        background-color: #FEFEFE;
        margin: auto;
        padding:40px 10px;
        border:1px solid #888;
        width:80%;
        height:70%;
        box-sizing: content-box;
        .modal-content {
            width:100%;
            height:100%;
        }
    }
    .close {
        color: #aaa;
        float:right;
        font-size:28px;
        font-weight:bold;
        &:hover, &:focus {
            color: #555;
            text-decoration:none;
            cursor:pointer;
        }
    }
}
